import React, { useState, useEffect } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleNavbar = () => {
    const navbar = document.querySelector(".navbar");
    navbar.classList.toggle("show");
  };

  const handleNavigation = (path) => {
    navigate(path);
    toggleNavbar(); // Close the menu after navigation
  };


  return (
    <header className="main-header">
    <nav className={`navbar ${isOpen ? "show" : ""}`}>
      <div className="content">
        <div className="logo pr-5">
          <img src={logo} alt="Logo" />
        </div>
        <ul className="menu-list">
          <li>
            <a href="/" onClick={() => handleNavigation("/#home")}>
              Home
            </a>
          </li>
         
          <li>
            <a href="#about" onClick={() => handleNavigation("/#about")}>
              About
            </a>
          </li>
          <li>
            <a href="#courses" onClick={() => handleNavigation("/#courses")}>
              Courses
            </a>
          </li>
          <li>
            <a href="#volunteer" onClick={() => handleNavigation("/#volunteer")}>
              Get Involved
            </a>
          </li>
          <li>
            <a href="/contact" onClick={() => handleNavigation("/contact")}>
              Contact
            </a>
          </li>
          <li>
            <a href="#questions" onClick={() => handleNavigation("/#questions")}>
              FAQ
            </a>
          </li>
          <li>
            <a href="#partners" onClick={() => handleNavigation("/#partners")}>
              Partners
            </a>
          </li>
        </ul>
        <div className="icon menu-btn" onClick={toggleNavbar}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <div class="icon cancel-btn" onClick={toggleNavbar}>
          <i class="fas fa-times"></i>
        </div>
      </div>
    </nav>
    </header>
  );
};

export default Header;