import "./App.css";
import React from "react"
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Topbar";
import Footer from "../src/components/Footer";
import HomePage from "../src/pages/Home";
import Contact from "./pages/Contact";
import ThankYou from "./pages/ThankYou";
import SchoolContact from "./pages/Contact/SchoolContact";

const AppContent = () => {
  const location = useLocation();

  return (
    <div className="app-container">
   <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/contact-school" element={<SchoolContact />} />
        </Routes>
      </div>
   <Footer />
    </div>
  );
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;