import React, { useEffect } from "react";
import thankYouImage from "../../assets/images/thank-you.png";

const ThankYou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="py-5">
        <div className="col-12">
          <div className="row display-flex-justify-center height-100 display-flex-vertical-center">
            <div className="col-12 text-center">
              <div>
                <img src={thankYouImage} alt="thank-you" width="531px" height="362px"/>
              </div>
              <p className="mt-5 font-size-24">
                <strong> Thanks for getting in touch!</strong>
              </p>
              <p className="mt-1 font-size-24">
                Our team will take a look at your message
                <br />
                and reach out to you soon.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ThankYou;
