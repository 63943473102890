import React, { useEffect, useRef, useState } from "react";
import "./home.css";
import CustomAccordion from "../../components/Accordion";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import circleplus from "../../assets/images/circleplus.png";
import nysha from "../../assets/images/nysha.png";
import hackerbank from "../../assets/images/hackerbank.svg";
import student from "../../assets/images/student.png";
import twocircle from "../../assets/images/2circle.png";
import triangledots from "../../assets/images/triangledots.png";
import nikhil from "../../assets/images/nikhil.png";
import weddmmaer from "../../assets/images/weddmmaer.png";
import rikhil from "../../assets/images/rikhil.png";

import sid from "../../assets/images/sid.png";
import nhshelter from "../../assets/images/nhshelter.png";
import natp from "../../assets/images/natp.png";
import parth from "../../assets/images/parth.png";
import donation from "../../assets/images/donation_5256505.png";
import lochan from "../../assets/images/lochan.png";
import linkedinIco from "../../assets/images/linkedin-icon.png";
import instagramIco from "../../assets/images/instagram-icon.png";
import placeholder from "../../assets/images/placeholder.png";
import thankYouTemp from "../../assets/images/thank-you.png";
import stsavio from "../../assets/images/stsavio.png";
import svcs from "../../assets/images/svcs.png";

import image7 from "../../assets/images/intro_to_coding_icon.png";
import squiggle from "../../assets/images/squiggle.png";
import image9 from "../../assets/images/public_speaking_icon.png";
import image10 from "../../assets/images/arrows_down.png";
import image11 from "../../assets/images/python_icon.png";
import image12 from "../../assets/images/drawing_board.png";
import image13 from "../../assets/images/screenpoint.png";
import image14 from "../../assets/images/techsupport.png";
import image15 from "../../assets/images/Chapter-image.png";
import codingfilledLogo from "../../assets/images/coding_active.png";
import pythonfilledLogo from "../../assets/images/python_active.png";
import publicspeakingfilledLogo from "../../assets/images/public_speaking_active.png";

const HomePage = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All");
  const partnerRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  function scrollToCourses(event) {
    event.preventDefault();

    const coursesSection = document.getElementById("courses");
    if (coursesSection) {
      window.scrollTo({
        top: coursesSection.offsetTop,
        behavior: "smooth",
      });
    }
  }

  function scrollToParter(event) {
    event.preventDefault();

    const coursesSection = document.getElementById("becomepartner");
    if (coursesSection) {
      window.scrollTo({
        top: coursesSection.offsetTop,
        behavior: "smooth",
      });
    }
  }

  function openNewTab(url) {
    window.open(url, "_blank");
  }

  const accordianData = [
    {
      title: "How will the students be taught? ",
      content:
        "Students will be taught by our dedicated team of volunteers through a virtual platform, such as Zoom. The volunteers will guide the students through the curriculum, and the students are able to follow along.",
    },
    {
      title: "What curriculum will be provided by TechLit?",
      content:
        "The curriculum will be curated specially by TechLit’s curriculum team. They have lots of experience in teaching their core subjects, so they use their knowledge to create engaging lesson plans that are short and sweet.",
    },
    {
      title: "What is the time commitment needed?",
      content:
        "The lessons will take place one to two times per week. Volunteers might need to dedicate more time with the onboarding process.",
    },
  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="">
      <section id="home" className="section div-gray-odd pt-5 mt-3 p-0">
        <div className="container">
          <div className="row mt-2">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 d-flex justify-content-end">
              <div className="row">
                <img
                  src={circleplus}
                  className="mt-3"
                  alt="image"
                  height="69px"
                  width="87px"
                />{" "}
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5">
              <div className="title mb-2">Bridging the Gap</div>
              <div className="subheading mt-2">
                <span className="highlight-hd"> TechLit</span> Empowers with
                Inclusive Education
              </div>
              <div className="para mt-3 pr-4">
                {" "}
                TechLit is a 501(c)3 nonprofit with a dedicated mission to{" "}
                <br />
                provide practical computer science and speaking skill
                <br /> education to under-resourced communities.
              </div>
              <div className="">
                <div className="d-flex justify-content-between">
                  <a href="#courses">
                    <button
                      className="button-everywhere mt-4"
                      onClick={scrollToCourses}
                    >
                      Discover More{" "}
                    </button>
                  </a>
                  {/* <div className="col-xl-5 col-lg-5 col-sm-6 col-xs-6 mt-5">
                    <img
                      src={squiggle}
                      alt="vector-2"
                      width="267.28px"
                      height="41.58px"
                      className="margin-left-70px mt-5"
                    />
                  </div> */}
                </div>

                <div className="col-xl-6 col-lg-6 col-md-10 col-sm-12 col-xs-12 mt-3">
                  <img
                    src={twocircle}
                    className="img-fluid mt-5"
                    alt="vector-2"
                    width="98px"
                    height="88px"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5">
              <div className="front-visible mt-5">
                <img
                  src={student}
                  className="mt-5 img-fluid z-index"
                  alt="vector-1"
                  id="student-studying"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>
          </div>
        </div>
      </section>

      <section className=" mb-5" id="about">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <div className="title-about"> About TechLit</div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <div className="row justify-content-end mt-5">
                <img src={squiggle} alt="wave" height="34" width="218.56" />
              </div>
            </div>
          </div>
          <div className="row mt-5 ">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="subheading-bold mt-5"> General Profile </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
              <div className="para mt-5">
                {" "}
                We are a nonprofit organization that bridges the technology gap
                by providing{" "}
                <span className="highlight-hd"> inclusive education </span> in
                computer science and speaking skills. <br />
                <br />
                We do this by hosting virtual meetings at a school (also known
                as a beneficiary), where dedicated volunteers who are
                well-versed in the field, guide students through the curriculum.
                The meetings are held during times your school finds convenient.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="subheading-bold mt-5"> Mission and Vision </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12 mt-5">
              <div className="para">
                {" "}
                We are committed to empowering individuals by providing
                equitable access to practical computer science and English
                skills education. We aim to bridge the technology gap and equip
                under-resourced communities with the knowledge and tools they
                need to thrive in the digital age.
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-5">
              <img
                src={triangledots}
                alt="image"
                height="101px"
                width="136px"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section" id="about">
  <div className="container">
    <div className="row justify-content-center align-items-center">
      <div className="col-12">
        <div className="title-about text-center">Our Impact</div>
      </div>
    </div>
    <div className="container-school row justify-content-center">
      <div className="col-md-4 col-sm-12">
        <a href="https://svcschool.edu.in/" target="_blank" rel="noopener noreferrer" className="mycard">
          <div className="cimg">
            <img src={svcs} alt="Sri Vivekananda" />
          </div>
          <div className="cdetail">
            <h2>Swami Vivekananda Central School</h2>
            <p>We piloted TechLit's curriculum virtually for Swami Vivekananda's students to learn about the basics of computer programming.</p>
          </div>
        </a>
      </div>
      <div className="col-md-4 col-sm-12">
        <a href="https://saintsavioschool.com/home/" target="_blank" rel="noopener noreferrer" className="mycard">
          <div className="cimg">
            <img src={stsavio} alt="Saint Savio" />
          </div>
          <div className="cdetail">
            <h2>Saint Savio <br /> High School</h2>
            <p>We then moved to help Saint Savio's students learn speech and debate fundamentals, as well as coding skills.</p>
          </div>
        </a>
      </div>
      <div className="col-md-4 col-sm-12">
        <a href="https://www.facebook.com/newhopeshelterecuador" target="_blank" rel="noopener noreferrer" className="mycard">
          <div className="cimg">
            <img src={nhshelter} alt="New School" />
          </div>
          <div className="cdetail">
            <h2>New Hope Shelter</h2>
            <p>We partnered with the New Hope Shelter to offer an introduction to programming for students in Ecuador.</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>






      <section className="section div-gray-odd pt-5 mt-5 pb-3" id="partners">
        <div className="align-items-center my-5">
          <div>
            <div className="titlePartner mt-5"> Current Partners </div>
            <div className="partnerBoxes">
              <div className="partnerBox">
                <div className="partnerInner">
                  <div className="partnerFront">
                    <img src={hackerbank} height="100"></img>
                  </div>

                  <p href="https://hackclub.com/" className="partnerBack">
                    <a href="https://hackclub.com/" className="partnerDesc">
                      Hack Club Bank is a financial platform designed to empower
                      high school students to manage and fund their creative
                      projects and initiatives independently. We recieve our
                      501(c)(3) status through Hack Club's program.
                    </a>
                  </p>
                </div>
              </div>

              <div className="partnerBox">
                <div className="partnerInner">
                  <div className="partnerFront">
                    <img src={nysha} height="90"></img>
                  </div>

                  <p className="partnerBack">
                    <a href="https://nysha.ai/" className="partnerDesc">
                      Nysha seeks to advance the finding of a cure for cystic
                      fibrosis through outreach and the use of advanced
                      technologies. Public awareness and early detection will
                      alleviate suffering and bring hope to the thousands of
                      families fighting the disease.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section mt-5 pt-5 mb-5">
        <div className="container">
          <div class="title">Our Team</div>
          <div className="row align-items-center pt-2">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-xs-8 mt-4">
                <div class="team-item">
                  <img src={nikhil} class="team-img" alt="pic" />
                  <h3>NIKHIL PRABHU</h3>
                  <div class="team-info">
                    <p>Founder and CEO</p>
                  </div>
                  <p className="p-info">
                    Nikhil believes that every student should have the chance to
                    gain skills in essential areas in order to have a prepared
                    future.
                  </p>

                  <ul class="team-icon">
                    <li>
                      <img
                        onClick={() =>
                          openNewTab(
                            "https://www.linkedin.com/in/nikhil-prabhu-521b84250"
                          )
                        }
                        src={linkedinIco}
                        className="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6 col-md-4 mt-4">
                <div class="team-item">
                  <img src={lochan} class="team-img" alt="pic" />

                  <h3>LOCHAN REDDY</h3>

                  <div class="team-info">
                    <p>COO</p>
                  </div>

                  <p className="p-info">
                    Lochan is excited to be able to provide his service to one
                    of the most impacted fields: computer science.
                  </p>

                  <ul class="team-icon">
                    <li>
                      <img
                        src={linkedinIco}
                        onClick={() =>
                          openNewTab(
                            "https://www.linkedin.com/in/lochan-reddy-537372297/"
                          )
                        }
                        class="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>

                    <li>
                      <img
                        src={instagramIco}
                        onClick={() =>
                          openNewTab(
                            "https://www.instagram.com/lochannreddy/?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                          )
                        }
                        class="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 mt-4">
                <div class="team-item">
                  <img src={weddmmaer} class="team-img" alt="pic" />

                  <h3>WEDDMMAER REMY</h3>

                  <div class="team-info">
                    <p>CTO</p>
                  </div>

                  <p className="p-info">
                  Weddmmaer is looking to capitalize on his development skills to help underrepresented and minority students.
                  </p>

                  <ul class="team-icon">
                    <li>
                      <img
                        src={instagramIco}
                        onClick={() =>
                          openNewTab(
                            "https://www.instagram.com/__wremy?igsh=MWp1dm1pYmVzdGtnOA%3D%3D&utm_source=qr"
                          )
                        }
                        class="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6 col-md-4 mt-4">
                <div class="team-item">
                  <img src={parth} class="team-img" alt="pic" />

                  <h3>PARTH Bansal</h3>

                  <div class="team-info">
                    <p>CGO</p>
                  </div>

                  <p className="p-info">
                    Parth looks to take TechLit to new heights by finding
                    institutions to partner with and effectively reach more
                    students around the globe
                  </p>

                  <ul class="team-icon">
                    <li>
                      <img
                        src={linkedinIco}
                        onClick={() =>
                          openNewTab(
                            "https://www.linkedin.com/in/parth-bansal-5960a0274/"
                          )
                        }
                        class="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6 col-md-4 mt-4">
                <div class="team-item">
                  <img src={sid} class="team-img" alt="pic" />

                  <h3>SID KARTHIK</h3>

                  <div class="team-info">
                    <p>CMO</p>
                  </div>

                  <p className="p-info">
                  Sid is a budding entrepreneur and is excited to leverage his
                   marketing experience to promote essential tech skills to 
                   students across the world. 
                  </p>

                  <ul class="team-icon">
                    <li>
                      <img
                        src={linkedinIco}
                        onClick={() =>
                          openNewTab(
                            "https://www.linkedin.com/in/siddarth-karthik-04519428b/"
                          )
                        }
                        class="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>

                    
                  </ul>
                </div>
              </div>

              <div class="col-sm-6 col-md-4 mt-4">
                <div class="team-item">
                  <img src={rikhil} class="team-img" alt="pic" />

                  <h3>RIKHIL DAMARALA</h3>

                  <div class="team-info">
                    <p>CFO</p>
                  </div>

                  <p className="p-info">
                  Rikhil has experience in managing financial operations effectively, with an 
                  an interest in pursuing quantitative finance and AI.

                  </p>

                  <ul class="team-icon">
                    <li>
                      <img
                        src={linkedinIco}
                        onClick={() =>
                          openNewTab(
                            "https://www.linkedin.com/in/rikhil-damarla-500a6a2b0/"
                          )
                        }
                        class="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-6 col-md-4 mt-4">
                <div class="team-item">
                  <img src={natp} class="team-img" alt="pic" />

                  <h3>NATASHA PRABHU</h3>

                  <div class="team-info">
                    <p>Debate Curriculum Coordinator</p>
                  </div>

                  <p className="p-info">
                    Natasha hopes everyone can have opportunities to
                    suceed in speech and knows TechLit is the way to
                    go.
                  </p>

                  <ul class="team-icon">
                    <li>
                      <img
                        src={linkedinIco}
                        onClick={() =>
                          openNewTab(
                            "https://www.linkedin.com/in/natasha-prabhu-03a7a61b0/"
                          )
                        }
                        class="socials-img"
                        style={{ cursor: "pointer" }}
                      ></img>
                    </li>
                  </ul>
                </div>
              </div>

             
         
            </div>
          </div>
        </div>
      </section>

      <div className="div-gray-odd" id="courses">
        <div className="container py-5">
          <div className="title-courses pt-5"> Our Courses</div>
          <div className="para-courses">
            {" "}
            Hover over to check our course descriptions{" "}
          </div>
          <div className="col-xl-12 col-sm-12 py-4">
            <ul className="row justify-content-center list-unstyled font-size-24 ">
              <li
                className={`pt-4 subheading-bold-courses ${
                  activeTab === "All" ? "active-tag" : "color-gray"
                }`}
                onClick={() => handleTabClick("All")}
              >
                <strong className="subheading-bold-courses">All</strong>
              </li>
              <li
                className={`pt-4 subheading-bold-courses ${
                  activeTab === "Coding" ? "active-tag" : "color-gray"
                }`}
                onClick={() => handleTabClick("Coding")}
              >
                <strong className="subheading-bold-courses">
                  Computer Science
                </strong>
              </li>
              <li
                className={`pt-4 subheading-bold-courses ${
                  activeTab === "Speech & Debate" ? "active-tag" : "color-gray"
                }`}
                onClick={() => handleTabClick("Speech & Debate")}
              >
                <strong className="subheading-bold-courses">
                  Speech & Debate
                </strong>
              </li>
            </ul>
          </div>

          <div className="row justify-content-center">
            {(activeTab === "All" || activeTab === "Coding") && (
              <div class="col-sm-4 col-md-4 mt-4">
                <div class="course-item">
                  <img
                    src={codingfilledLogo}
                    alt="pic"
                    className="courses-logo mt-4"
                    height="79px"
                    width="auto"
                  />

                  <h3 className>Intro to Coding</h3>

                  <p className="course-p-info mt-3">
                    Learn coding essentials in the first course taught by
                    international volunteers specializing in computer science.
                    They will use block coding to ensure solid fundamentals.
                  </p>
                </div>
              </div>
            )}

            {(activeTab === "All" || activeTab === "Coding") && (
              <div class="col-sm-4 col-md-4 mt-4">
                <div class="course-item">
                  <img
                    src={pythonfilledLogo}
                    alt="pic"
                    className="courses-logo mt-4"
                    height="79px"
                    width="auto"
                  />

                  <h3 className>Python Essentials</h3>

                  <p className="course-p-info mt-3">
                    Explore Python programming in this course. Learn core
                    concepts like variables, data types, control structures, and
                    functions through hands-on experience and projects.
                  </p>
                </div>
              </div>
            )}

            {(activeTab === "All" || activeTab === "Speech & Debate") && (
              <div class="col-sm-4 col-md-4 mt-4">
                <div class="course-item">
                  <img
                    src={publicspeakingfilledLogo}
                    alt="pic"
                    className="courses-logo mt-4"
                    height="79px"
                    width="auto"
                  />

                  <h3 className>Public Speaking</h3>

                  <p className="course-p-info mt-3">
                    Introduce yourself to practical English skills for
                    real-world scenarios. Learn to construct persuasive
                    arguments, enhance critical thinking, and articulate
                    thoughts effectively.
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="row justify-content-center">
            <div className="text-center course-images mt-3">
              <a href="#becomepartner">
                <img
                  src={image10}
                  alt="image"
                  height="px"
                  width="46px"
                  onClick={scrollToParter}
                />{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container" id="becomepartner">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3">
          <div className="row justify-content-start align-items-center my-5">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
              <div className="div-white-even">
                <div className="title"> Become a Partner! </div>
                <div
                  className="subheading-bold"
                  style={{ marginBottom: "4px" }}
                >
                  {" "}
                  Are you a school interested in receiving virtual lessons from
                  TechLit?{" "}
                </div>
                <div className="para" style={{ marginBottom: "28px" }}>
                  {" "}
                  Any school that enrolls to our service becomes our partner;{" "}
                  they get to learn and grasp the knowledge for free through
                  online lessons.{" "}
                </div>
                <button
                  className="button-everywhere"
                  onClick={() => openNewTab("https://forms.gle/751HrSbsMtoJeiJ36")}
                >
                  <a className="text-white"> Partner With Us!</a>
                </button>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3">
              <img
                className="whiteboard-boy"
                src={image12}
                alt="image"
                width="647px"
                height="667.35px"
              />{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="div-gray-odd mb-4" id="volunteer">
        <div className="container py-5">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 my-5">
            <div className="title"> Want to Spread the Knowledge? </div>
            <div className="row align items center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                <img
                  className="whiteboard-teacher align-items-center"
                  src={image13}
                  height="397"
                  width="637"
                  alt="image"
                />{" "}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                <div className="subheading-bold"> Become a virtual mentor </div>
                <div className="para">
                  {" "}
                  A virtual TechLit volunteer/mentor will help ignite young
                  minds in the captivating realms of computer science and
                  debate.{" "}
                </div>
                <div className="para-bold">
                  {" "}
                  Requirements and Qualifications:{" "}
                </div>
                <ul className="para pt-3">
                  <li>
                    Have an intermediate to advanced level and passion in
                    computer science
                  </li>
                  <li>
                    Have an intermediate to advanced level in public speaking
                    and debate
                  </li>
                </ul>
                <div className="disclaimer">
                  {" "}
                  (depending on which you plan to help teach){" "}
                </div>
                <button
                  className="button-everywhere"
                  onClick={() =>
                    openNewTab(
                      "https://docs.google.com/forms/d/e/1FAIpQLSc3-rdvvs1DEQIduOffWgLSSbSiskJSxnCHc7vtnlKXwxCddw/viewform"
                    )
                  }
                >
                  <a className="text-white">Apply Now</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {<div className="mb-4" id="making-a-chapter">
        <div className="container py-5">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 my-5">
            <div className="title"> Want to Start a Chapter? </div>
            <div className="row align items center">
              
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
                <div className="subheading-bold"> Become a Chapter President </div>
                <div className="para">
                  {" "}
                  As a Chapter President, you’ll establish and lead a local TechLit chapter, inspiring students to explore computer science and debate. Your leadership will create a space where young minds develop critical thinking, innovate, and collaborate, shaping the next generation of leaders.{" "}
                </div>
                <div className="para-bold mt-3">
                  {" "}
                  Roles and Duties:{" "}
                </div>
                <ul className="para pt-3 mt-2">
                  <li>
                    Recruiting and managing members of the chapter.
                  </li>
                  <li>
                    Reporting to the TechLit board regarding operations.
                  </li>
                  <li>
                    Managing education efforts for the school that has been assigned to the chapter.
                  </li>
                </ul>
                <div className="disclaimer">
                  {" "}
                  (Duties can be changed depending on where you are starting a chapter){" "}
                </div>
                <button
                  className="button-everywhere"
                  onClick={() =>
                    openNewTab(
                      "https://docs.google.com/forms/d/e/1FAIpQLSfrdtV3OcDPdhH91a454-PimAZqIenr99iYVA1CEBrQjN0XFw/viewform"
                    )
                  }
                >
                  <a className="text-white">Apply Now</a>
                </button>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 custom-column">
                <img
                  className="whiteboard-teacher align-items-center"
                  src={image15}
                  height="550"
                  width="557"
                  alt="image"
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>}
      

      <section className="di div-gray-odd " id="about">
    <div className="mb-4" id="volunteer">
        <div className="container py-5">
            <div className="row align-items-center">
                <div className="col-12 mt-3 my-5">
                    <div className="title">Donate to the Cause</div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-1">
                    <div className="embed-responsive" style={{height: '400px'}}>
                        <iframe
                            src="https://hcb.hackclub.com/donations/start/techlit"
                            className="donation-iframe"
                            name="donateFrame"
                            scrolling="yes"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
          <div className="subheading-bold">Support Underprivileged Students</div>
          <div className="para">
            A donation to the TechLit nonprofit goes directly toward
            funding our stellar academic courses, allowing students across
            the world to have access to high quality education.
          </div>
          <br />
          <div className="para-bold pt-2">Your Donations Will:</div>
          <ul className="para pt-3">
            <li className="pt-3">
              Improve our computer science courses, as well as our speech
              and debate course, directly benefitting students
            </li>
            <li className="pt-3">
              Help foster a love for learning in young kids who otherwise
              do not have access to such materials
            </li>
          </ul>
          <button
            className="button-everywhere"
            onClick={() =>
              window.open("https://hcb.hackclub.com/donations/start/techlit", "_blank")
            }
          >
            <a className="text-white">Donate Now</a>
          </button>
                    </div>
                </div>
            </div>
        </div>
      </section>


      <section id="home" className="section 0 p-0">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3 mb-3">
            <div className="title"> Lets Connect! </div>
            <div className="subheading-question">
              {" "}
              Do you have any questions? Want to discuss? We are here to help
              you!{" "}
            </div>
            <button
              className="button-everywhere"
              style={{ cursor: "pointer" }}
              onClick={() => openNewTab("/contact")}
            >
              {" "}
              Contact Us
            </button>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
            <div className="d-flex justify-content-lg-end justify-content-sm-center">
              <img
                src={image14}
                draggable="false"
                alt="image"
                width="647px"
                height="667.35px"
                className="tech-support"
              />{" "}
            </div>
          </div>
        </div>
      </div>
     </section>
                  <hr class="rounded"></hr>

      <div className="section div-gray-odd" id="questions">
        <div className="container">
          <div className="row justify-content-between align-items-center py-5">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="title-question pt-5">
                Frequently Asked Questions
              </div>
              <p className="para-question">We have the answers!</p>
              <button
                onClick={() => navigate("/contact")}
                className="button-everywhere mb-5"
              >
                Contact Now
              </button>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-12 col-sm-12 col-xs-12">
              <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 0.2) " }}></hr>
            </div>
            <div className="col-xl-6.5 col-lg-6.5 col-md-12 col-sm-12 col-xs-12">
              <div className="accordion">
                {accordianData.map(({ title, content }) => (
                  <div className="mt-3 bg-white">
                    <CustomAccordion title={title} content={content} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
