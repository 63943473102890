import React from "react";
import "./index.css";
import footerLogo from "../../assets/images/footerLogo.png";
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faInstagram, faTiktok , faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const navigate = useNavigate();

  function openNewTab(url) {
    window.open(url, "_blank");
  }

  return (
    <footer>
      <div className="footer-content footer-background-color">
        <h3> TechLit </h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 justify-content-center">
       <div className="row justify-content-center">
          <button className="button-footer mt-2 mb-4" onClick={() => openNewTab('https://docs.google.com/forms/d/e/1FAIpQLSfCf-Cf_ZaYAvidXxSdy2zEHKqwMdQplqpLnMfmfl6vLT6Hpg/viewform')}>
            Get Involved! </button>
         
          <button className="button-footer mt-2 mb-4" onClick={() => openNewTab('https://mail.google.com/mail/u/0/?fs=1&to=techlitus@gmail.com&su=Interest In TechLit&body=&tf=cm')}>
            Email Us! </button>
            </div>
        </div>
        <h4 class="PhoneNumber"> (925) 307-9355</h4>
        <ul className="socials">
          <li>
            <FontAwesomeIcon icon={faLinkedin} onClick={() => openNewTab('https://www.linkedin.com/in/techlit-nonprofit-045011282')} size="3x" />
          </li>
          <li>
            <FontAwesomeIcon icon={faInstagram} onClick={() => openNewTab('https://instagram.com/techlitus?utm_source=qr')} size="3x" />
          </li>
         
        </ul>
        <div className="justify-content-center">
          <img src={footerLogo} alt="img" width="120px" height="120px" />
        </div>

        <div className="footer-bottom">
          <p>
            TechLit is a 501(c)(3) nonprofit fiscally sponsored by Hack Club.
            Our registered EIN is 81-2908499.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
