import React, { useState } from "react";
import styles from "./styles.css";
import wave from "../../../assets/images/wave.png";
import circleDots from "../../../assets/images/circle-with-dots.png";
import circleHorizontal from "../../../assets/images/circle-with-horizontal.png";
import triangleBlankDots from "../../../assets/images/triangle-blank-dots.png";
import triangleDotsRight from "../../../assets/images/triangle-dots-right.png";

const Schoolcontact = () => {
  const [formData, setFormData] = useState({
    yourName: "",
    Email: "",
    mobileNo: "",
    organisationName: "",
    address: "",
    coursesInterested: {
      IntroductiontoCoding: false,
      PythonBasics: false,
      PublicSpeakingEssentials: false,
    },
    country: "",
    technologyAvailable: "",
    daysOfWeek: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
    },
    questions: "",
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      coursesInterested: {
        ...formData.coursesInterested,
        [name]: !formData.coursesInterested[name],
      },
    });
  };

  const handleDaysChange = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      daysOfWeek: {
        ...formData.daysOfWeek,
        [name]: !formData.daysOfWeek[name],
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      if (typeof value === "object" && !Array.isArray(value)) {
        for (const [subKey, subValue] of Object.entries(value)) {
          formDataToSend.append(`${key}[${subKey}]`, subValue);
        }
      } else {
        formDataToSend.append(key, value);
      }
    }

    fetch(
      "https://script.google.com/macros/s/AKfycbxW9xld10fv69331TmbQ4uX_W6xCaUmB7h1aVihIiCSJXxQBKkydms-sF4dacOXe7-BLw/exec",
      {
        method: "POST",
        body: formDataToSend,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // Reset the form after successful submission
        setFormData({
          yourName: "",
          Email: "",
          mobileNo: "",
          organisationName: "",
          address: "",
          coursesInterested: {
            IntroductiontoCoding: false,
            PythonBasics: false,
            PublicSpeakingEssentials: false,
          },
          country: "",
          technologyAvailable: "",
          daysOfWeek: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
          },
          questions: "",
        });
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 7000); // Hide message after 7 seconds
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={styles.contactContainer}>
      <img src={wave} alt="Wave" className={`${styles.backgroundImage} ${styles.wave}`} />
      <img src={circleDots} alt="Circle with Dots" className={`${styles.backgroundImage} ${styles.circleDots}`} />
      <img src={circleHorizontal} alt="Circle Horizontal" className={`${styles.backgroundImage} ${styles.circleHorizontal}`} />
      <img src={triangleBlankDots} alt="Triangle Blank Dots" className={`${styles.backgroundImage} ${styles.triangleBlankDots}`} />
      <img src={triangleDotsRight} alt="Triangle Dots Right" className={`${styles.backgroundImage} ${styles.triangleDotsRight}`} />
      <div className={styles.formContainer}>
        <div className={styles.formContent}>
          <h2>Let's Talk</h2>
          <p>Got something in mind? We'd love to help.</p>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <label htmlFor="yourName">Your Name</label>
              <input
                type="text"
                id="yourName"
                name="yourName"
                value={formData.yourName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="Email">Email</label>
              <input
                type="email"
                id="Email"
                name="Email"
                value={formData.Email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="mobileNo">Mobile No.</label>
              <input
                type="tel"
                id="mobileNo"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="organisationName">Organization Name</label>
              <input
                type="text"
                id="organisationName"
                name="organisationName"
                value={formData.organisationName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <label htmlFor="address">Address</label>
              <textarea
                id="address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            
              
        
           
          
            {/* Success message displayed near the submit button */}
            <p className={styles.successMessage}>{showSuccessMessage && "Thank You! Your response has been submitted successfully. Our team will contact you."}</p>
            
            {/* Submit button */}
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Schoolcontact;
