import React, { useEffect, useState } from "react";
import circleDots from "../../assets/images/circle-with-dots.png";
import wave from "../../assets/images/wave.png";
import circleHorizontal from "../../assets/images/circle-with-horizontal.png";
import triangleBlanckDots from "../../assets/images/triangle-blank-dots.png";
import triangleDots from "../../assets/images/triangle-dots-right.png";
import "./index.css";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const contactNumberRegex = /^([+]\d{2})?\d{10}$/;
  const validname = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
  const [formValue, setFormValue] = useState({
    query: "",
    name: "",
    email: "",
    mobileNo: "",
  });
  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputField = (e) => {
    console.log("Event ", e);
    const { name, value } = e.target;

    if (name === "query") {
      setFormValue({
        ...formValue,
        query: value,
      });
      setErrorMsg({ ...errorMsg, queryError: false });
    }
    if (name === "userName") {
      setFormValue({
        ...formValue,
        name: value,
      });
      setErrorMsg({ ...errorMsg, nameError: false });
    }
    if (name === "email") {
      setFormValue({
        ...formValue,
        email: value,
      });
      setErrorMsg({ ...errorMsg, emailError: false });
    }
    if (name === "contactNumber") {
      setFormValue({
        ...formValue,
        mobileNo: value,
      });
      setErrorMsg({ ...errorMsg, contactNumberError: false });
    }
  };

  const handleSendEnquiry = async () => {
    const isWhitespace = (value) => {
      return /^\s*$/.test(value);
    };

  if (isWhitespace(formValue.query)) {
    setErrorMsg({ ...errorMsg, queryError: true });
    return;
  }
  if (isWhitespace(formValue.name)) {
    setErrorMsg({ ...errorMsg, nameError: true });
    return;
  }
  if (isWhitespace(formValue.mobileNo)) {
    setErrorMsg({ ...errorMsg, contactNumberError: true });
    return;
  }
  if (isWhitespace(formValue.email)) {
    setErrorMsg({ ...errorMsg, emailError: true });
    return;
  }
  if (formValue.email && !emailRegex.test(formValue.email)) {
    setErrorMsg({ ...errorMsg, emailError: true });
    return;
  }
  if (formValue.name && !validname.test(formValue.name)) {
    setErrorMsg({ ...errorMsg, nameError: true });
    return;
  }
  if (formValue.mobileNo && !contactNumberRegex.test(formValue.mobileNo)) {
    setErrorMsg({ ...errorMsg, contactNumberError: true });
    return;
  }
};


const sendEmail = (e) => {
  e.preventDefault();

  
  const emailParams = {
    to_email: formValue.email, 
    to_name: formValue.name,
    from_email: "techlitus@gmail.com",
    contact_number: formValue.mobileNo,
    query: formValue.query,

  };

  emailjs
    .send(
      "service_xhkiwga",
      "template_luasmxr",
      emailParams,
      "P001TJXVjZp8j9F5b"
    )
    .then(
      (response) => {
        console.log("Email sent successfully!", response);
        navigate("/thank-you");
        setFormValue({
          query: "",
          name: "",
          email: "",
          mobileNo: "",
        });
      },
      (error) => {
        console.error("Error sending email: ", error);
      }
    );
};

return (
  <div id="contact-us" className="container-fluid">
    <div className="container">
      <div className="row  mb-5 justify-content-between align-items-end">
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12 py-5 mt-5">
          <div className="d-flex justify-content-center ">
            <img
              src={wave}
              className="text-center"
              alt="wave"
              width="180px"
              height="25.7px"
            />
          </div>
        </div>
        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-5 col-xs-12">
          <div className="row justify-content-end ">
            <img src={circleDots} alt="circle" width="40px" height="96.34px" />
          </div>
        </div>
      </div>

      <div className="row  mb-5 justify-content-start align-items-end">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div className="para mt-2"> Let's Talk</div>
          <div className="row align-items-center">
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 margin-left-70">
              <div className="d-flex justify-content-end">
                <div className="yellow-border"></div>
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <div className="font-size-48 ">
                <div className="title-bold"> Let Us Know How We Can Help </div>
              </div>
            </div>
          </div>
          <div className="subheading pt-4">
            Got something in mind? We'd love to help.
          </div>
        </div>

        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="row justify-content-start  align-items-end margin-top-negative">
            <img
              src={circleHorizontal}
              alt="circle"
              width="120.26px"
              height="114px"
            />
          </div>
        </div>
      </div>
      <div className="row  justify-content-start align-items-end">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="row justify-content-between align-items-end subheading-gray">
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 pt-5">
              <div className="">
                Post your question now, and we'll get in touch with you soon!
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
              <div className="row justify-content-end  align-items-end mt-5">
                <img
                  src={triangleBlanckDots}
                  alt="circle"
                  width="64.64px"
                  height="61.48px"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <textarea
                className="mt-3 form-control"
                name="query"
                rows="8"
                placeholder="Enter your question here"
                value={formValue.query}
                onChange={handleInputField}
              />
              <div className="pt-1">
                {errorMsg && errorMsg.queryError && (
                  <span style={{ color: "red" }}>
                    Please enter your question
                  </span>
                )}
              </div>
              <p className="font-size-24 mt-3">
                <strong>What are you looking for?</strong>
              </p>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <input
                    name="userName"
                    value={formValue.name}
                    placeholder="Name"
                    className="mt-2 form-control"
                    onChange={handleInputField}
                  />
                  <div className="pt-1">
                    {errorMsg && errorMsg.nameError && (
                      <span style={{ color: "red" }}>
                        Please enter your name
                      </span>
                    )}
                  </div>
                  <input
                    name="contactNumber"
                    value={formValue.mobileNo}
                    placeholder="123-456-7890"
                    className="mt-4 form-control"
                    onChange={handleInputField}
                  />
                  <div className="pt-1">
                    {errorMsg && errorMsg.contactNumberError && (
                      <span style={{ color: "red" }}>
                        Please enter a valid mobile number
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <input
                    name="email"
                    value={formValue.email}
                    placeholder="name@email.com"
                    className="mt-2 form-control"
                    onChange={handleInputField}
                  />
                  <div className="pt-1">
                    {errorMsg && errorMsg.emailError && (
                      <span style={{ color: "red" }}>
                        Please enter a valid email
                      </span>
                    )}
                  </div>
                </div>
                <button
                  onClick={sendEmail}
                  className=" row align-items-center button-everywhere mt-5 ml-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-sm-center justify-content-lg-end ">
        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-xs-12">
          <div className="d-flex justify-content-between align-items-end">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-5 col-xs-12 pb-4">
              <img src={wave} alt="wave" width="180px" height="25.5px" />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-5 col-xs-12 pb-4">
              <img src={triangleDots} alt="wave" width="110.4" height="75.46" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
                
export default Contact;
