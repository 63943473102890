import React, { useState } from "react";
import "./index.css";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={`accordion-item ${isActive ? "active" : ""}`}>
      <div className="row justify-content-between py-4 px-5" onClick={() => setIsActive(!isActive)}>
        <div className="accordion-title cursor-pointer">{title}</div>
        <div className="accordion-title cursor-pointer">{isActive ? "-" : "+"}</div>
      </div>
      <div className="accordion-content" style={{ maxHeight: isActive ? "1000px" : "0" }}>
        <div className="pl-4 pr-3 pb-4">{content}</div>
      </div>
    </div>
  );
};

export default Accordion;
